import React from 'react';

import Helmet from 'react-helmet';

import banner from '../assets/img/banner.jpg';

export default (props) => (
  <Helmet>
    <title>{props.title}</title>
    <link rel="canonical" href={props.canonical} />
    <meta name="description" content={props.description} />

    <meta name="og:type" content="website" />
    <meta name="og:title" content={props.title} />
    <meta name="og:description" content={props.keywords} />
    <meta name="og:image" content={props.banner || banner} />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={props.title} />
    <meta name="twitter:description" content={props.keywords} />
    <meta name="twitter:image" content={props.banner || banner} />
  </Helmet>
);
